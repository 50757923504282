import { TypePageSection } from '../../../../@types/generated';
import z from 'zod';
import { captureException } from '../../../utils/tracking/sentry/sentry';

const settingsSchema = z.object({
  video: z
    .object({
      landscape: z.union([z.number(), z.string()]).optional(),
      portrait: z.union([z.number(), z.string()]).optional(),
    })
    .optional(),
  fixedMediaHeight: z.boolean().optional(),
  dynamicMediaHeight: z.boolean().optional(),
  lottieAnimationDesktop: z.string().optional(),
  lottieAnimationMobile: z.string().optional(),
});

const orientedMediaToCompatibleProps = ({
  orientedMedia,
  image,
  settings = {},
}: Pick<TypePageSection['fields'], 'orientedMedia' | 'image'> & {
  settings?: Record<string, unknown>;
}) => {
  const { data: parsedSettings, error } = settingsSchema.safeParse(settings);
  if (error) captureException(error);

  return {
    image: orientedMedia?.fields.imageLandscape || orientedMedia?.fields.imagePortrait || image,
    imageLandscape: orientedMedia?.fields.imageLandscape,
    imagePortrait: orientedMedia?.fields.imagePortrait,
    videoIdLandscape: orientedMedia?.fields.videoIdLandscape || parsedSettings?.video?.landscape,
    videoIdPortrait: orientedMedia?.fields.videoIdPortrait || parsedSettings?.video?.portrait,
    fixedHeight: orientedMedia?.fields.fixedHeight || parsedSettings?.fixedMediaHeight,
    dynamicHeight: orientedMedia?.fields.dynamicHeight || parsedSettings?.dynamicMediaHeight,
    maxWidth: orientedMedia?.fields.maxWidth,
    lottieAnimationDesktop:
      orientedMedia?.fields.lottieAnimationDesktop || parsedSettings?.lottieAnimationDesktop,
    lottieAnimationMobile:
      orientedMedia?.fields.lottieAnimationMobile || parsedSettings?.lottieAnimationMobile,
  };
};

export default orientedMediaToCompatibleProps;
