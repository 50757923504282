import styled from '@emotion/styled';
import { useRef } from 'react';
import { useOutsideClick } from '../../../../../utils/hooks/useOutsideClick';
import { colors, spacing } from '../../../../../utils/styleguide';
import { NavItemType } from '../../navTypes';
import SubmenuLink from './SubmenuLink';
import Container from '../../../layout/Container';

const SubmenuWrapper = styled.div`
  position: absolute;
  width: 100%;
  background: ${colors.white};
  padding: ${spacing[4]}px ${spacing[5]}px;
  border-bottom: 1px solid ${colors.grey02};
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;
  border-left: 1px solid ${colors.grey03};
`;

type SubmenuProps = {
  links?: NavItemType[] | undefined;
  clickOutside: (event: Event) => void;
  onLinkClicked: () => void;
};

const Submenu = ({ links, clickOutside, onLinkClicked }: SubmenuProps) => {
  const submenuRef = useRef(null);
  useOutsideClick({ innerRef: submenuRef, onOutsideClick: clickOutside });
  if (!links) return null;

  return (
    <SubmenuWrapper ref={submenuRef}>
      <Container disableVerticalPadding>
        <LinkContainer>
          {links.map(({ title, href, description }: NavItemType, index) => {
            return (
              <SubmenuLink
                key={index}
                href={href}
                title={title}
                description={description}
                onClick={onLinkClicked}
              />
            );
          })}
        </LinkContainer>
      </Container>
    </SubmenuWrapper>
  );
};

export default Submenu;
