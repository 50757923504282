import { TypePageSection } from '../../../../@types/generated';
import getSettingsFromSection from '../../../utils/getSettingsFromSection';
import { Color, colors } from '../../../utils/styleguide';
import { captureException } from '../../../utils/tracking/sentry/sentry';
import buttonToCompatibleProps from './buttonToCompatibleProps';
import orientedMediaToCompatibleProps from './orientedMediaToCompatibleProps';
import z from 'zod';

const settingsSchema = z.object({
  reversed: z.boolean().optional(),
  reversedMobile: z.boolean().optional(),
  backgroundLinear: z.string().optional(),
});

const sectionToCompatibleProps = ({ section }: { section: TypePageSection }) => {
  const {
    background,
    backgroundEntry,
    buildingBlock,
    orientedMedia,
    title,
    description,
    help,
    reversed,
    reversedMobile,
    image,
    variant,
  } = section.fields || {};

  const settings = getSettingsFromSection(section);
  const media = orientedMediaToCompatibleProps({ orientedMedia, image, settings });
  const button = buttonToCompatibleProps({ button: buildingBlock?.fields.button, settings });
  const backgroundMedia = orientedMediaToCompatibleProps({
    orientedMedia: backgroundEntry?.fields.orientedMedia,
  });

  const { data: parsedSettings, error } = settingsSchema.safeParse(settings);
  if (error) captureException(error);

  return {
    id: getSectionId({ section }),
    title: buildingBlock?.fields.title || title,
    description: buildingBlock?.fields.description || description,
    overline: buildingBlock?.fields.preheader,
    media,
    help,
    reversed: reversed || parsedSettings?.reversed,
    reversedMobile: reversedMobile || parsedSettings?.reversedMobile,
    backgroundImage: backgroundMedia.image || background,
    backgroundImageLandscape: backgroundMedia.imageLandscape,
    backgroundImagePortrait: backgroundMedia.imagePortrait,
    backgroundColor: getBackgroundColor({ backgroundEntry, settings }),
    backgroundLinear: backgroundEntry?.fields.linear || parsedSettings?.backgroundLinear,
    backgroundOverlay: backgroundEntry?.fields.overlay,
    button,
    logos: buildingBlock?.fields.logos,
    variant,
    settings,
  };
};

const getBackgroundColor = ({
  backgroundEntry,
  settings,
}: Pick<TypePageSection['fields'], 'backgroundEntry'> & { settings: Record<string, unknown> }):
  | string
  | undefined => {
  if (backgroundEntry?.fields.color) {
    return colors[backgroundEntry.fields.color];
  }
  if (!settings?.backgroundColor) {
    return;
  }

  const settingsBackgroundColor =
    'backgroundColor' in settings && typeof settings.backgroundColor === 'string'
      ? settings.backgroundColor
      : undefined;

  if (settingsBackgroundColor && Object.keys(colors).includes(settingsBackgroundColor)) {
    return colors[settingsBackgroundColor as Color];
  }

  if (settingsBackgroundColor && /^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(settingsBackgroundColor)) {
    return settingsBackgroundColor;
  }

  return undefined;
};

export const getSectionId = ({ section }: { section: TypePageSection }) => {
  const settings = getSettingsFromSection(section);

  if ('id' in settings && typeof settings.id === 'string') return settings.id;

  return section.sys.id;
};

export default sectionToCompatibleProps;
