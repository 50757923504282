import applePay from './apple-pay.svg';
import americanExpress from './american-express.svg';
import americanExpressSolid from './american-express-solid.svg';
import archive from './archive.svg';
import arrowLeft from './arrow-left.svg';
import arrowLeftLong from './arrow-left-long.svg';
import arrowRight from './arrow-right.svg';
import arrowRightLong from './arrow-long-right.svg';
import bcgLogo from './bcg-logo.svg';
import arrowDown from './down.svg';
import bee from './bee.svg';
import beeVertical from './bee-vertical.svg';
import bin from './bin.svg';
import Camera from './camera.svg';
import caution from './caution.svg';
import changePolicy from './change-policy.svg';
import checkFilled from './check-filled.svg';
import checkSquareFilled from './check-square-filled.svg';
import check from './check.svg';
import checkmark from './checkmark.svg';
import chevronDownOutline from './chevron-down-outline.svg';
import chevronDown from './chevron-down.svg';
import chevronSDownOutline from './chevron-s-down-outline.svg';
import ChevronRight from './chevron-right.svg';
import chevronSDown from './chevron-s-down.svg';
import chevronSUp from './chevron-s-up.svg';
import Clock from './clock.svg';
import Calendar from './calendar.svg';
import ContainersAndCloud from './containers-and-cloud.svg';
import creditCard from './credit-card.svg';
import defaultCardIcon from './default-card-icon.svg';
import download from './download.svg';
import upload from './upload.svg';
import uploadCloud from './upload-cloud.svg';
import externalLink from './external-link.svg';
import Edit from './edit.svg';
import facebook from './facebook.svg';
import facebookRounded from './facebook-rounded.svg';
import gamingController from './gaming-controller.svg';
import goldStandardLogo from './gold-standard-logo.svg';
import mail from './mail.svg';
import twitter from './twitter.svg';
import fileText from './file-text.svg';
import Film from './film.svg';
import Fog from './fog.svg';
import hamburger from './hamburger.svg';
import helpCircleFill from './help-circle-fill.svg';
import instagram from './instagram.svg';
import infoOutline from './info-outline.svg';
import lightning from './lightning.svg';
import linkedin from './linkedin.svg';
import lockFilled from './lock-filled.svg';
import lockCheckFilled from './lock-check-fill.svg';
import lock from './lock.svg';
import longArrow from './long-arrow.svg';
import longArrowDown from './long-arrow-down.svg';
import mapMarkerFilled from './map-marker-filled.svg';
import mastercard from './mastercard.svg';
import mastercardSolid from './mastercard-solid.svg';
import milkywireFull from './milkywire-full.svg';
import milkwyireLogoBrand from './milkywire-logo-brand.svg';
import milkywireMastercardLogo from './milkywire-mastercard-logo.svg';
import milkywireLogoBlack from './milkywire-logo-black.svg';
import milkwyireLogoOnYellow from './milkywire-logo-on-yellow.svg';
import milkywireSymbol from './milkywire-symbol.svg';
import milkywireWordmark from './milkywire-wordmark.svg';
import pangaiaBeeTextWithTrail from './pangaia-bee-text-with-trail.svg';
import pangaiaBeeText from './pangaia-bee-text.svg';
import pangaiaBeeTrail from './pangaia-bee-trail.svg';
import pangaiaBeeTrail2 from './pangaia-bee-trail2.svg';
import pangaiaBee from './pangaia-bee.svg';
import paypalSolid from './paypal-solid.svg';
import Pause from './pause.svg';
import Play from './play.svg';
import Plus from './plus.svg';
import poweredByMilkywire from './powered-by-milkywire.svg';
import mastercardPPCLogo from './mastercard-ppc-logo-lockup.svg';
import mastercardPPCLogoColor from './mastercard-ppc-logo-lockup-color.svg';
import RightCircleRill from './right-circle-fill.svg';
import RightDownCircleRill from './right-down-circle-fill.svg';
import RightUpCircleRill from './right-up-circle-fill.svg';
import rocket from './rocket.svg';
import sbtLogo from './sbt-logo.svg';
import star from './rating-star.svg';
import solidOnLight from './solid-on-light.svg';
import ThumbsDown from './thumbs-down.svg';
import ThumbsDownFill from './thumbs-down-fill.svg';
import UserGroup from './user-group.svg';
import ThumbsUp from './thumbs-up.svg';
import ThumbsUpFill from './thumbs-up-fill.svg';
import Tree from './tree.svg';
import Video from './video.svg';
import PlayVideo from './play-video.svg';
import X2 from './x2.svg';
import Coin2 from './coin-2.svg';
import InfoOutline2 from './info-outline2.svg';
import dashboard from './dashboard.svg';
import Stars from './stars.svg';
import wwfLogo from './wwf-logo.svg';

// Scribble
import scribbleCircle from './scribble-circle.svg';
import search from './search.svg';
import stripeIcon from './stripe-icon.svg';
import sustainableDevelopmentGoals from './sustainable-development-goals.svg';
import sdgSquare from './sdg-square.svg';
import trail4 from './trail4.svg';
import visa from './visa.svg';
import visaSolid from './visa-solid.svg';
import volumeMute from './volume-mute.svg';
import volumeOn from './volume-on.svg';
import xCross from './x-cross.svg';
import xFilled from './x-filled.svg';
import xOl from './x-ol.svg';
import xSymbol from './x-symbol.svg';
import youtube from './youtube.svg';

import klarna from './logos/klarna.svg';
import pangaia from './logos/pangaia.svg';
import stripe from './logos/stripe.svg';
import wriDark from './logos/wri_dark.svg';
import wriLight from './logos/wri_light.svg';
import ciDark from './logos/ci_dark.svg';
import ciLight from './logos/ci_light.svg';

export default {
  /**
   * @deprecated
   */
  'milkywire-full': milkywireFull,
  'milkywire-symbol': milkywireSymbol,
  'milkywire-wordmark': milkywireWordmark,
  facebook,
  'facebook-rounded': facebookRounded,
  'gaming-controller': gamingController,
  mail,
  twitter,
  instagram,
  lightning,
  linkedin,
  youtube,
  hamburger,
  dashboard,
  'long-arrow': longArrow,
  'long-arrow-down': longArrowDown,
  camera: Camera,
  caution,
  'chevron-down': chevronDown,
  'chevron-down-outline': chevronDownOutline,
  'chevron-s-down-outline': chevronSDownOutline,
  'chevron-right': ChevronRight,
  'chevron-s-down': chevronSDown,
  'chevron-s-up': chevronSUp,
  clock: Clock,
  calendar: Calendar,
  'containers-and-cloud': ContainersAndCloud,
  'credit-card': creditCard,
  edit: Edit,
  'file-text': fileText,
  film: Film,
  fog: Fog,
  'map-marker-filled': mapMarkerFilled,
  search,
  'arrow-left': arrowLeft,
  'arrow-left-long': arrowLeftLong,
  'arrow-right': arrowRight,
  'arrow-long-right': arrowRightLong,
  'arrow-down': arrowDown,
  star: star,
  'scribble-circle': scribbleCircle,
  video: Video,
  'volume-mute': volumeMute,
  'volume-on': volumeOn,
  'x-cross': xCross,
  'x-filled': xFilled,
  'x-ol': xOl,
  lock,
  'lock-check-filled': lockCheckFilled,
  'lock-filled': lockFilled,
  'check-filled': checkFilled,
  'check-square-filled': checkSquareFilled,
  check,
  checkmark,
  'x-symbol': xSymbol,
  pangaia,
  stripe,
  bee,
  'bee-vertical': beeVertical,
  'pangaia-bee': pangaiaBee,
  'pangaia-bee-text-with-trail': pangaiaBeeTextWithTrail,
  trail4,
  tree: Tree,
  'pangaia-bee-trail': pangaiaBeeTrail,
  'pangaia-bee-text': pangaiaBeeText,
  'pangaia-bee-trail2': pangaiaBeeTrail2,
  'stripe-icon': stripeIcon,
  klarna,
  'sustainable-development-goals': sustainableDevelopmentGoals,
  'sdg-square': sdgSquare,
  bin,
  download,
  upload,
  'upload-cloud': uploadCloud,
  'milkywire-logo-brand': milkwyireLogoBrand,
  'milkywire-logo-black': milkywireLogoBlack,
  'milkywire-mastercard-logo': milkywireMastercardLogo,
  'powered-by-milkywire': poweredByMilkywire,
  /**
   * @deprecated
   */
  'milkywire-logo-on-yellow': milkwyireLogoOnYellow,
  'apple-pay': applePay,
  'american-express': americanExpress,
  'american-express-solid': americanExpressSolid,
  'default-card-icon': defaultCardIcon,
  visa,
  'visa-solid': visaSolid,
  mastercard,
  'mastercard-solid': mastercardSolid,
  'mastercard-ppc-logo-lockup': mastercardPPCLogo,
  'mastercard-ppc-logo-lockup-color': mastercardPPCLogoColor,
  'paypal-solid': paypalSolid,
  archive,
  'solid-on-light': solidOnLight,
  'external-link': externalLink,
  'help-circle-fill': helpCircleFill,
  'change-policy': changePolicy,
  rocket,
  'right-up-circle-fill': RightUpCircleRill,
  'right-circle-fill': RightCircleRill,
  'right-down-circle-fill': RightDownCircleRill,
  pause: Pause,
  play: Play,
  plus: Plus,
  'info-outline': infoOutline,
  'thumbs-up': ThumbsUp,
  'thumbs-up-fill': ThumbsUpFill,
  'thumbs-down': ThumbsDown,
  'thumbs-down-fill': ThumbsDownFill,
  'user-group': UserGroup,
  'play-video': PlayVideo,
  x2: X2,
  'coin-2': Coin2,
  'info-outline2': InfoOutline2,
  stars: Stars,
  'bcg-logo': bcgLogo,
  'gold-standard-logo': goldStandardLogo,
  'sbt-logo': sbtLogo,
  'wwf-logo': wwfLogo,
  'ci-logo-dark': ciDark,
  'ci-logo-light': ciLight,
  'wri-logo-dark': wriDark,
  'wri-logo-light': wriLight,
};
