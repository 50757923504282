import { Entry } from 'contentful';
import {
  isTypeButton,
  TypeButton,
  TypeButtonSkeleton,
} from '../../../../@types/generated/TypeButton';
import {
  isTypeContextualButton,
  TypeContextualButtonSkeleton,
} from '../../../../@types/generated/TypeContextualButton';
import getLinkHref from './getLinkHref';

const buttonToCompatibleProps = ({
  button,
  settings,
}: {
  button?: Entry<
    TypeButtonSkeleton | TypeContextualButtonSkeleton,
    'WITHOUT_UNRESOLVABLE_LINKS',
    string
  >;
  settings?: Record<string, unknown>;
}) => {
  if (button && isTypeButton(button)) {
    return {
      label: button.fields.label,
      prompt: button.fields.prompt,
      href: getLinkHref(button.fields.link) || button.fields.href,
      colorContext: button.fields.colorContext,
      size: button.fields.size,
      variant: button.fields.variant,
      trailingIconName: button.fields.trailingIconName,
      leadingIconName: button.fields?.leadingIconName,
    };
  } else if (button && isTypeContextualButton(button)) {
    return {
      label: button.fields.label,
      prompt: button.fields.prompt,
      href: getLinkHref(button.fields.link),
    };
  } else {
    const settingsButton = {
      label: (settings?.CTAText || settings?.buttonLabel) as string | undefined,
      prompt: settings?.CTAPrompt as string | undefined,
      href: settings?.href as string | undefined,
      colorContext: (settings?.buttonColor || settings?.CTAColor) as
        | TypeButton['fields']['colorContext']
        | undefined,
      size: (settings?.buttonLarge || 'large') as TypeButton['fields']['size'] | undefined,
      variant: undefined,
      trailingIconName: undefined,
      leadingIconName: undefined,
    };
    if (settingsButton.label) {
      return settingsButton;
    }
    return;
  }
};

export default buttonToCompatibleProps;
