import config from '../config';
import { captureException } from '../tracking/sentry/sentry';
const CDN_API_URL = config.CDN_API_URL;

type Settings = {
  clientSettings: ClientSettings;
  serverInfo: Record<string, unknown>;
};
type ClientSettings = {
  PANGAIA_CAMPAIGN_PROJECT_ID?: number;
  PANGAIA_CAMPAIGN_VIDEO_ID?: number;
  PANGAIA_CAMPAIGN_COLLECTION_LINK?: string;
  TAKE_ACTION_REVIEWS_SLUG?: string;
  ACCOUNTING_SYSTEM_MAINTENANCE?: string;
  IMPACTER_TERMS_OF_USE_SLUG?: string;
};

export const getSettings = async (): Promise<Settings> => {
  try {
    const settings = await fetch(`${CDN_API_URL}tools/v2/settings?client=web`);
    const { clientSettings, serverInfo } = await settings.json();

    return {
      clientSettings: clientSettings.reduce(
        (
          acc: ClientSettings,
          { key, value }: { key: string; value?: number | string | boolean },
        ) => ({ ...acc, [key]: value }),
        {},
      ),
      serverInfo,
    };
  } catch (e) {
    captureException(e);
    return { clientSettings: {}, serverInfo: {} };
  }
};
