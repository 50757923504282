import { AmplitudeClient } from 'amplitude-js';
import { useRef, useState, useEffect, useCallback } from 'react';
import { TypePaymentOption } from '../../../@types/generated';
import { FlowType, PaymentType, Provider, SubscriptionFlowType } from '../../types/PaymentTypes';
import { EVENT_NAMES, EVENT_PROPERTIES } from './events';

// Re-exporting for accessibility reasons
export { EVENT_NAMES };
export type EVENT_NAMES_TYPE = typeof EVENT_NAMES[keyof typeof EVENT_NAMES];

type State = {
  amplitude?: AmplitudeClient;
  shouldQueue: boolean;
  queue: (
    | { name: 'setUserProperties'; params: Parameters<typeof setUserProperties> }
    | { name: 'sendEvent'; params: Parameters<typeof sendEvent> }
    | { name: 'sendEventProxy'; params: Parameters<typeof sendEventProxy> }
  )[];
};

export const state: State = {
  shouldQueue: process.env.RUNTIME_ENV === 'browser',
  queue: [],
};

type setUserPropertiesProps = {
  id?: string | number;
  properties?: {
    [name: string]: string | number | boolean | unknown[] | Record<string, unknown>;
  };
};

export const setupAmplitude = async (hasCookieConsent: boolean) => {
  const { setupAmplitude } = await import('./amplitudeTracking');
  state.amplitude = await setupAmplitude(hasCookieConsent);
  state.queue.forEach((item) => {
    switch (item.name) {
      case 'sendEvent':
        sendEvent(...item.params);
        return;
      case 'sendEventProxy':
        sendEventProxy(...item.params);
        return;
      case 'setUserProperties':
        setUserProperties(...item.params);
        return;
    }
  });
};

export const setUserProperties = async ({ id, properties }: setUserPropertiesProps) => {
  const { amplitude, shouldQueue, queue } = state;
  if (!amplitude) {
    if (shouldQueue) {
      queue.push({ name: 'setUserProperties', params: [{ id, properties }] });
    }
    return;
  }

  const { setUserProperties } = await import('./amplitudeTracking');

  setUserProperties({ id, properties });
};

type Options = {
  addNetworkAnalytics?: boolean;
  logServerSide?: boolean;
  addUtmTags?: boolean;
};

export async function sendEvent<N extends EVENT_NAMES_TYPE>(
  event: {
    name: N;
    properties?: EVENT_PROPERTIES[N];
  },
  { addNetworkAnalytics = false, logServerSide = false, addUtmTags = true }: Options = {
    addNetworkAnalytics: false,
    logServerSide: false,
    addUtmTags: false,
  },
) {
  const { amplitude, shouldQueue, queue } = state;
  if (!amplitude) {
    if (shouldQueue) {
      queue.push({ name: 'sendEvent', params: [event, { addNetworkAnalytics }] });
    }
    return;
  }

  const { sendEvent } = await import('./amplitudeTracking');
  sendEvent(event, {
    addNetworkAnalytics,
    logServerSide,
    addUtmTags,
  });
}

/**
 *
 * Simplified sendEvent through proxy for EXPERIMENT
 * Only use if you know you should use it
 */
export async function sendEventProxy<N extends EVENT_NAMES_TYPE>(event: {
  name: N;
  properties: EVENT_PROPERTIES[N];
}) {
  const { amplitude, shouldQueue, queue } = state;
  if (!amplitude) {
    if (shouldQueue) {
      queue.push({ name: 'sendEventProxy', params: [event] });
    }
    return;
  }

  const { sendEventProxy } = await import('./amplitudeTracking');
  sendEventProxy(event);
}

export function sendServerEvent<N extends EVENT_NAMES_TYPE>(event: {
  name: N;
  properties: EVENT_PROPERTIES[N];
}) {
  import('./amplitudeServerTracking').then(({ sendServerEvent }) => sendServerEvent(event));
}

/**
 * CUSTOM TRACKING
 * @deprecated use typed tracking instead
 **/
export function trackInitiatePayment({
  initiator,
  campaign,
  impacterIds,
  categoryId,
  categoryName,
  impacterNames,
  projectId,
  paymentFlowId,
  flowType,
  provider,
  amountConfiguration,
}: {
  initiator: string;
  campaign?: string;
  impacterIds?: Array<string | number>;
  categoryId?: string;
  categoryName?: string;
  impacterNames?: Array<string>;
  projectId?: number;
  paymentFlowId: string;
  flowType?: FlowType | SubscriptionFlowType;
  provider?: 'stripe' | 'klarna';
  amountConfiguration?:
    | TypePaymentOption['fields']['oneTimeAmountConfiguration']
    | TypePaymentOption['fields']['subscriptionAmountConfiguration'];
}) {
  sendEvent({
    name: EVENT_NAMES.supportClickSupport,
    properties: {
      initiator,
      campaign,
      'support type': PaymentType.ONE_TIME,
      'flow type': flowType,
      'impacter ids': impacterIds,
      'impacter names': impacterNames,
      'category id': categoryId,
      'category name': categoryName,
      'project id': projectId,
      'payment flow id': paymentFlowId,
      provider: provider,
      'amount configuration': amountConfiguration,
    },
  });
}

export enum ChangeType {
  amount = 'amount',
  toggle = 'toggle',
  currency = 'currency',
}

type SendSupportDonationChangeEventType = {
  amount?: number;
  currencyCode?: string;
  paymentProvider?: Provider;
  supportType: string;
  path: string;
  changeType: ChangeType;
  flowType: FlowType | SubscriptionFlowType;
};

/**
 * CUSTOM TRACKING
 * @deprecated use typed tracking instead
 **/
export function sendSupportDonationChangeEvent({
  amount,
  currencyCode,
  paymentProvider,
  supportType,
  path,
  changeType,
  flowType,
}: SendSupportDonationChangeEventType) {
  sendEvent({
    name: EVENT_NAMES.supportDonationChange,
    properties: {
      amount,
      'currency code': currencyCode,
      'payment provider': paymentProvider,
      'support type': supportType,
      path: path,
      'change type': changeType,
      'flow type': flowType,
    },
  });
}

type SendPaymentIntentFailedEventType = {
  amount?: number;
  currencyCode?: string;
  paymentProvider: Provider;
  supportType: string;
  path: string;
  flowType: FlowType | SubscriptionFlowType;
  selectedWallet: string;
  error: string;
};

/**
 * CUSTOM TRACKING
 * @deprecated use typed tracking instead
 **/
export function sendPaymentIntentFailedEvent({
  amount,
  currencyCode,
  paymentProvider,
  supportType,
  path,
  flowType,
  selectedWallet,
  error,
}: SendPaymentIntentFailedEventType) {
  sendEvent({
    name: EVENT_NAMES.supportPaymentIntentFailed,
    properties: {
      amount,
      'currency code': currencyCode,
      'payment provider': paymentProvider,
      'support type': supportType,
      path,
      'flow type': flowType,
      'selected wallet': selectedWallet,
      error,
    },
  });
}

type sendPaymentIntentSuccessEventType = {
  amount?: number;
  currencyCode?: string;
  paymentProvider: Provider;
  supportType: string;
  path: string;
  flowType: FlowType | SubscriptionFlowType;
  selectedWallet: string;
};

/**
 * CUSTOM TRACKING
 * @deprecated use typed tracking instead
 **/
export function sendPaymentIntentSuccessEvent({
  amount,
  currencyCode,
  paymentProvider,
  supportType,
  path,
  flowType,
  selectedWallet,
}: sendPaymentIntentSuccessEventType) {
  sendEvent({
    name: EVENT_NAMES.supportPaymentIntentSuccess,
    properties: {
      amount,
      'currency code': currencyCode,
      'payment provider': paymentProvider,
      'support type': supportType,
      path,
      'flow type': flowType,
      'selected wallet': selectedWallet,
    },
  });
}

export const getDeviceId = () => state.amplitude?.options.deviceId;

const ELEMENT_SECONDS_IN_VIEW_THRESHOLD = 5;
/**
 * Tracks the time in seconds that an element stays in the viewport.
 */
export function useTrackTimeInView<T extends HTMLElement>(name: string) {
  const elementRef = useRef<T>(null);
  const isIntersectingRef = useRef(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [startIntersectingTime, setStartIntersectingTime] = useState(0);

  const trackTimeInView = useCallback(() => {
    const secondsSinceStartIntersecting = (Date.now() - startIntersectingTime) / 1000;

    if (secondsSinceStartIntersecting > ELEMENT_SECONDS_IN_VIEW_THRESHOLD) {
      sendEvent({
        name: EVENT_NAMES.timeInView,
        properties: {
          name,
          seconds: Math.round(secondsSinceStartIntersecting),
        },
      });
    }
  }, [startIntersectingTime, name]);

  useEffect(() => {
    const handleVisibilitychange = () => {
      if (document.visibilityState === 'hidden' && isIntersectingRef.current) {
        trackTimeInView();
      } else {
        setStartIntersectingTime(Date.now());
      }
    };
    window.addEventListener('visibilitychange', handleVisibilitychange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilitychange);
    };
  }, [trackTimeInView]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      { threshold: 0.5 },
    );

    const element = elementRef.current;
    if (!element) {
      return;
    }

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isIntersecting && !isIntersectingRef.current) {
      setStartIntersectingTime(Date.now());
      isIntersectingRef.current = true;
    } else if (!isIntersecting && isIntersectingRef.current) {
      trackTimeInView();
      isIntersectingRef.current = false;
    }
  }, [isIntersecting, trackTimeInView]);

  return elementRef;
}
