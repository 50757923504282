import { css, Global } from '@emotion/react';
import KHTekaLightSupport from '../../../assets/fonts/KHTeka-Light.woff';
import KHTekaLight from '../../../assets/fonts/KHTeka-Light.woff2';
import KHTekaMediumSupport from '../../../assets/fonts/KHTeka-Medium.woff';
import KHTekaMedium from '../../../assets/fonts/KHTeka-Medium.woff2';
import KHTekaRegularSupport from '../../../assets/fonts/KHTeka-Regular.woff';
import KHTekaRegular from '../../../assets/fonts/KHTeka-Regular.woff2';
import { getAssetUrlWithAssetPrefix } from '../../../utils/assetUrl';

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'KH Teka';
        font-weight: 300;
        src: url('${getAssetUrlWithAssetPrefix(KHTekaLight)}') format('woff2'),
          url('${getAssetUrlWithAssetPrefix(KHTekaLightSupport)}') format('woff');
      }
      @font-face {
        font-family: 'KH Teka';
        font-weight: 400;
        src: url('${getAssetUrlWithAssetPrefix(KHTekaRegular)}') format('woff2'),
          url('${getAssetUrlWithAssetPrefix(KHTekaRegularSupport)}') format('woff');
      }
      @font-face {
        font-family: 'KH Teka';
        font-weight: 500;
        src: url('${getAssetUrlWithAssetPrefix(KHTekaMedium)}') format('woff2'),
          url('${getAssetUrlWithAssetPrefix(KHTekaMediumSupport)}') format('woff');
      }
      * {
        font-family: 'KH Teka';
      }
    `}
  />
);

export default Fonts;
