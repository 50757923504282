import { useRouter } from 'next/router';
import { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { acceptTermsOfUse, getCurrentImpacterProfile, getImpacterProfile } from './api';
import { getSettings } from './api/settings';
import { getByUniqueSlug } from './api/contentful';
import { ImpacterProfile } from '../types/AuthTypes';
import { captureException } from './tracking/sentry/sentry';
import { TypeTermsOfUse } from '../../@types/generated';
import { useAuth } from './authContext';

type Impacter = ImpacterProfile;

type ImpactTerms = {
  updated?: string | null;
  slug?: string | null;
};

export type ImpactContext = {
  impacter: Impacter | null;
  impacterId: string | undefined;
  impactTerms: ImpactTerms | null;
  isAdminEnactingImpacter: boolean;
  clearAuthenticatedImpacter: () => void;
  acceptImpactTermsOfUse: () => Promise<void>;
};

const Context = createContext<ImpactContext | null>(null);
type Props = {
  children: ReactNode;
};
function AuthenticatedImpacterProvider(props: Props) {
  const { query } = useRouter();
  const { verifiedUser, fetchUser } = useAuth();

  const [asImpacter, setAsImpacter] = useState<string | undefined>(undefined);
  const [impacter, setImpacter] = useState<Impacter | null>(null);
  const [impactTerms, setImpactTerms] = useState<ImpactTerms | null>(null);

  const fetchImpacterProfile = useCallback(async (impacterId?: string) => {
    setImpacter(null);
    try {
      const currentImpacterProfile = impacterId
        ? await getImpacterProfile(impacterId)
        : await getCurrentImpacterProfile();
      setImpacter(currentImpacterProfile);
    } catch (error) {
      captureException(error);
    }
  }, []);

  const fetchImpactTermsOfUseUpdated = useCallback(async () => {
    try {
      const { clientSettings } = await getSettings();
      const termsSlug = clientSettings.IMPACTER_TERMS_OF_USE_SLUG;
      if (termsSlug) {
        const terms = await getByUniqueSlug<TypeTermsOfUse>({
          slug: termsSlug,
          contentType: 'termsOfUse',
        });
        if (terms) {
          setImpactTerms({ updated: terms.fields.dateUpdated, slug: termsSlug });
          return;
        }
      }
      if (process.env.NODE_ENV !== 'test') throw new Error('Could not retrieve terms of use slug');
    } catch (error) {
      captureException(error);
    }
  }, []);

  useEffect(() => {
    const storedAsImpacter = localStorage.getItem('asImpacter');
    setAsImpacter(storedAsImpacter ?? undefined);

    //! This should only be doable for admins preferably
    if (typeof query.asImpacter === 'string') {
      setAsImpacter(query.asImpacter);
    }
  }, [query.asImpacter]);

  useEffect(() => {
    if (!verifiedUser) return;

    if (verifiedUser.roles?.IMPACTER) {
      fetchImpacterProfile();
    }

    if (
      verifiedUser.roles?.IMPACTER ||
      verifiedUser.roles?.IMPACT_PARTNER_ADMIN ||
      verifiedUser.roles?.INITIATIVE_ADMIN
    ) {
      fetchImpactTermsOfUseUpdated();
    }
  }, [verifiedUser, fetchImpactTermsOfUseUpdated, fetchImpacterProfile]);

  useEffect(() => {
    const impacterIsDefinedButNotLocallyStored = !asImpacter && impacter?.id;

    if (verifiedUser?.roles?.ADMIN && asImpacter) {
      localStorage.setItem('asImpacter', asImpacter);
    } else if (impacterIsDefinedButNotLocallyStored) {
      localStorage.setItem('asImpacter', impacter.id);
    }

    const needToFetchImpacter = asImpacter && !impacter?.id;

    if (needToFetchImpacter) {
      if (
        verifiedUser?.roles?.ADMIN ||
        verifiedUser?.roles?.IMPACT_PARTNER_ADMIN ||
        verifiedUser?.roles?.INITIATIVE_ADMIN
      ) {
        fetchImpacterProfile(asImpacter);
      } else if (verifiedUser?.roles.IMPACTER) {
        fetchImpacterProfile();
      }
    }
  }, [asImpacter, impacter?.id, verifiedUser, fetchImpacterProfile]);

  const clearAuthenticatedImpacter = () => {
    setImpacter(null);
    localStorage.removeItem('asImpacter');
    setAsImpacter(undefined);
  };

  const acceptImpactTermsOfUse = useCallback(async () => {
    try {
      const slug = impactTerms?.slug;
      if (slug) {
        await acceptTermsOfUse(slug);
        fetchUser();
      } else {
        throw new Error('Impacter terms of use slug has not been set');
      }
    } catch (error) {
      captureException(error);
    }
  }, [impactTerms?.slug, fetchUser]);

  return (
    <Context.Provider
      value={{
        impacter,
        impacterId: verifiedUser?.roles.ADMIN ? asImpacter : impacter?.id,
        impactTerms,
        isAdminEnactingImpacter: Boolean(verifiedUser?.roles.ADMIN && asImpacter),
        clearAuthenticatedImpacter,
        acceptImpactTermsOfUse,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

function useAuthenticatedImpacter(): ImpactContext {
  const context = useContext(Context);
  if (context === null) {
    throw new Error(
      `useAuthenticatedImpacter must be used within an AuthenticatedImpacterProvider`,
    );
  }

  return context;
}

export { AuthenticatedImpacterProvider, useAuthenticatedImpacter };
