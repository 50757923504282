import NavBarContainer from './NavBarContainer';
import Link from 'next/link';
import Icon from '../../Icon';
import { colors } from '../../../../utils/styleguide';
import StaticHeaderWrapper from './StaticHeaderWrapper';
import styled from '@emotion/styled';

const Navigation = styled.div`
  pointer-events: auto;
`;

const RightAlignedContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export default function PoweredByMWNavBar({ color = colors.white }) {
  return (
    <StaticHeaderWrapper>
      <>
        <NavBarContainer hideBorder>
          <RightAlignedContent>
            <Navigation>
              <Link href={'/'} passHref>
                <Icon name={'powered-by-milkywire'} fill={color} size={80} />
              </Link>
            </Navigation>
          </RightAlignedContent>
        </NavBarContainer>
      </>
    </StaticHeaderWrapper>
  );
}
