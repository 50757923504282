import { css } from '@emotion/react';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { colors, NAVBAR_HEIGHT_L, transitionSpeeds } from '../../../../utils/styleguide';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const window = require('global/window');

type WrapperType = {
  backgroundColor?: string;
  top?: number;
};

const Wrapper = styled.nav`
  box-sizing: border-box;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  align-content: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  position: fixed;
  ${({ top }: WrapperType) => `top: ${top}px;`}
  left: 0;
  right: 0;
  transition: top ${transitionSpeeds.complex}, background-color ${transitionSpeeds.complex} ease;
  height: inherit;
  ${({ backgroundColor }: WrapperType) =>
    css`
      background-color: ${backgroundColor};
    `}
`;

type Props = {
  children: ({ textColor }: { textColor: string }) => ReactNode;
  preventHideNavBar?: boolean;
  hideNavBar?: () => void;
  initiallyTransparent?: boolean;
  overrideTransparent?: boolean;
  textColor?: string;
};

const HeaderWrapper = ({
  children,
  hideNavBar,
  preventHideNavBar,
  initiallyTransparent = false,
  overrideTransparent,
  textColor,
}: Props) => {
  const shouldBeTransparentAtTheTop = initiallyTransparent && !overrideTransparent;
  const showNavbarRef = useRef(true);
  const smoothScrollingDoneRef = useRef(false);
  const preventHideNavBarRef = useRef(preventHideNavBar);
  const offsetRef = useRef(0);
  const [navbarTop, setNavbarTop] = useState<number>(0);
  const [isTransparent, setIsTransparent] = useState(shouldBeTransparentAtTheTop);
  const navBarBackgroundColor = isTransparent ? 'transparent' : colors.white;
  const scrollColorRef = useRef<number>();

  const cachedChildren = useMemo(
    () => children({ textColor: isTransparent ? textColor || colors.white : colors.black }),
    [children, isTransparent, textColor],
  );

  useEffect(() => {
    // Preventing to hide NavBar
    if (preventHideNavBar) {
      preventHideNavBarRef.current = true;
      showNavbarRef.current = false;
    } else {
      showNavbarRef.current = true;
      preventHideNavBarRef.current = false;
    }
  }, [preventHideNavBar]);

  function handleMouseEnter() {
    setIsTransparent(false);
  }

  function handleMouseLeave() {
    setIsTransparent(shouldBeTransparentAtTheTop && getIsAtTheTop());
  }

  const scrollHandler = useCallback(() => {
    if (smoothScrollingDoneRef.current) {
      smoothScrollingDoneRef.current = false;
      delete window.smoothScrollingTo;
    }
    if (window.smoothScrollingTo === window.pageYOffset) {
      smoothScrollingDoneRef.current = true;
    }

    // Show navbar if near top or scrolling upwards
    const isScrolledUp = window.pageYOffset < 5 || window.pageYOffset < offsetRef.current;
    const isScrolledDown = window.pageYOffset >= 5 && window.pageYOffset > offsetRef.current;

    if (
      (isScrolledDown && showNavbarRef.current && !preventHideNavBarRef.current) ||
      window.smoothScrollingTo
    ) {
      if (hideNavBar) {
        hideNavBar();
      }

      setNavbarTop(-NAVBAR_HEIGHT_L);
      showNavbarRef.current = false;
    } else if (isScrolledUp && !showNavbarRef.current && !window.smoothScrollingTo) {
      setNavbarTop(0);
      showNavbarRef.current = true;
    }

    const previousIsAtTheTop = offsetRef.current == 0;
    const isAtTheTop = getIsAtTheTop();

    if (previousIsAtTheTop && !isAtTheTop) {
      // avoid brutal color change
      scrollColorRef.current = window.setTimeout(() => {
        setIsTransparent(false);
      }, 1000);
    }

    if (isAtTheTop) {
      if (scrollColorRef.current) {
        clearTimeout(scrollColorRef.current);
      }
      setIsTransparent(shouldBeTransparentAtTheTop);
    }

    offsetRef.current = window.pageYOffset;
  }, [hideNavBar, setIsTransparent, shouldBeTransparentAtTheTop]);

  useEffect(() => {
    const debouncedScroll = debounce(scrollHandler, 300, {
      leading: true,
      trailing: true,
      maxWait: 100,
    });
    window.addEventListener('scroll', debouncedScroll);
    debouncedScroll();
    return () => window.removeEventListener('scroll', debouncedScroll);
  }, [scrollHandler]);

  useEffect(() => {
    return () => {
      if (scrollColorRef.current) {
        clearTimeout(scrollColorRef.current);
      }
    };
  }, []);

  return (
    <Wrapper
      id="navbar"
      aria-label="Header"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      backgroundColor={navBarBackgroundColor}
      top={navbarTop}
    >
      {cachedChildren}
    </Wrapper>
  );
};

function getIsAtTheTop() {
  return window.pageYOffset == 0;
}

export default HeaderWrapper;
