import styled from '@emotion/styled';
import { MutableRefObject, ReactNode } from 'react';
import { DeviceSpec } from '../../../utils/helpers/image';
import { breakpoints } from '../../../utils/styleguide';

const paddingToCss = (padding?: number) =>
  padding === Infinity
    ? `
    padding-top: 0;
    height: 100%;
    `
    : `
  padding-top: ${padding}%;
  height: 0;
  `;

const Box = styled.div<{ paddings: DeviceSpec }>`
  width: 100%;
  position: relative;
  ${({ paddings }) => `
    ${paddingToCss(paddings.mobile)}
    ${breakpoints.tablet} {
      ${paddingToCss(paddings.tablet)}
    }
    ${breakpoints.desktop} {
      ${paddingToCss(paddings.desktop)}
    }
  `}
`;

const contentWrapperPaddingToCss = (padding?: number) =>
  padding === Infinity
    ? `
    position: relative;
    height: 100%;
  `
    : `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `;

const ContentWrapper = styled.div<{ paddings: DeviceSpec }>`
  ${({ paddings }) => `
    ${contentWrapperPaddingToCss(paddings.mobile)}
    ${breakpoints.tablet} {
      ${contentWrapperPaddingToCss(paddings.tablet)}
    }
    ${breakpoints.desktop} {
      ${contentWrapperPaddingToCss(paddings.desktop)}
    }
  `}
`;

const getPadding = (ratio: number) => 100 * (1 / ratio);

function getAspectRatioPaddings(aspectRatio = 1, responsiveAspectRatios: DeviceSpec = {}) {
  const {
    mobile = aspectRatio,
    tablet = aspectRatio,
    desktop = aspectRatio,
  } = responsiveAspectRatios;

  return {
    mobile: getPadding(mobile),
    tablet: getPadding(tablet),
    desktop: getPadding(desktop),
  };
}

type Props = {
  children?: ReactNode;
  aspectRatio?: number;
  responsiveAspectRatios?: DeviceSpec;
  boxRef?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
};

const AspectRatioBox = ({
  children,
  responsiveAspectRatios,
  aspectRatio,
  boxRef,
  className,
}: Props) => {
  const paddings = getAspectRatioPaddings(aspectRatio, responsiveAspectRatios);
  return (
    <Box paddings={paddings} ref={boxRef} className={className}>
      <ContentWrapper paddings={paddings}>{children}</ContentWrapper>
    </Box>
  );
};

export default AspectRatioBox;
