import Head from 'next/head';
import { useRouter } from 'next/router';

const HOTJAR_PAGES_REGEX = [
  /.*\/impacter-tools.*/,
  /.*\/climate-transformation-fund$/,
  /^\/$/,
  /^\/ctf-donation$/,
  /^\/platform$/,
  /^\/sustainability.*/,
];

const Hotjar = () => {
  const router = useRouter();
  const addHotjarScript =
    process.env.NODE_ENV === 'production' &&
    HOTJAR_PAGES_REGEX.some((regex) => regex.test(router.asPath));

  if (!addHotjarScript) {
    return null;
  }

  return <HotjarWrapper />;
};

const HotjarWrapper = () => (
  <Head>
    <script
      dangerouslySetInnerHTML={{
        __html: `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2002976,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `,
      }}
    />
  </Head>
);

export default Hotjar;
