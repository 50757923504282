const mwLogo = `%c
 ▄█████████████████▄
▟███████████████████▙
███▛     ▜█▛     ▜███
███▏             ▕███
███               ███
███               ███
███▏             ▕███
███▙▂▂▂▟▙▂▂▂▟▙▂▂▂▟███
█████████████████████
▜███( milkywire )███▛
 ▀█████████████████▀
%c
https://www.milkywire.com/page/work-with-us

    ^ Apply here ^
`;

const easterEgg = () =>
  // eslint-disable-next-line
  console.log(
    mwLogo,
    'font-family: monospace',
    'font-family: helvetica; font-weight: bold; font-size: 1.3em;',
  );

export default easterEgg;
