import Link from 'next/link';
import { colors, spacing } from '../../../../../utils/styleguide';
import Typography from '../../../text/Typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChevronRight } from '@carbon/icons-react';

const LinkTitle = styled(Typography)`
  text-align: left;
`;

const LinkWrapper = css`
  cursor: pointer;
  display: flex;
  padding: 0 ${spacing[4]}px;
  align-self: stretch;
  text-decoration: none;
  gap: ${spacing[4]}px;
  align-items: center;
  border-left: 2px solid transparent;

  transition: all 520ms;
  transition-timing-function: cubic-bezier(0.54, 0, 0.2, 1.16);

  &:hover {
    border-left: 2px solid ${colors.purple400};
  }

  &:hover ${LinkTitle} {
    color: ${colors.purple500};
  }
`;

const LinkTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkText = styled.div`
  display: flex;
  gap: ${spacing[1]}px;
  align-items: center;
  text-decoration: none;
  padding: ${spacing[1]}px 0;
`;

const DescriptionText = styled.div`
  display: flex;
  width: 100%;
  text-decoration: none;
  text-align: left;
`;

const LinkButton = styled.button`
  background: none;
  border: none;
`;

type Props = {
  description?: string;
  href?: string;
  onClick: () => void;
  title?: string;
};

export default function SubmenuLink({ href, description, title, onClick }: Props) {
  if (!href && !onClick) {
    return null;
  }

  const LinkComponent = href ? Link : LinkButton;

  return (
    <LinkComponent href={href as string} aria-label={title} css={LinkWrapper} onClick={onClick}>
      <LinkTextWrapper>
        <LinkText>
          <LinkTitle variant="buttonSmall" tag="p">
            {title}
          </LinkTitle>
          <ChevronRight fill={colors.blackPrimary} />
        </LinkText>
        <DescriptionText>
          <Typography variant="bodySmall" color={colors.blackSecondary}>
            {description}
          </Typography>
        </DescriptionText>
      </LinkTextWrapper>
    </LinkComponent>
  );
}
