import { css, SerializedStyles } from '@emotion/react';
import { DeviceSpec, getPicture, ImageFit } from '../../utils/helpers/image';
import { getImageFitStyles, ImageFitWrapper } from './ImageFit';
import { memo } from 'react';

export type ImageProps = {
  alt?: string;
  fit?: ImageFit;
  lazyLoad?: boolean;
  fullBleed?: boolean;
  fileName?: string;
  url: string;
  disableContentfulScaling?: boolean;
} & ImageStyleProps &
  ({ fitWrapper?: false } | WrapperProps);

export type ImageStyleProps = {
  wrapperCss?: SerializedStyles;
  css?: SerializedStyles;
  className?: string;
  /**
   * specify what portion of the screen the image takes. For example if the image takes half of the screen, use 2
   */
  spans?: DeviceSpec;
  /**
   * specify what is the aspect ratio of the image. This is especially useful on mobile when the original image is landscape and should be rendered in portrait
   */
  aspectRatios?: DeviceSpec;
  maxWidth?: number;
};

type WrapperProps = {
  fitWrapper: true;
  deviceWidths?: DeviceSpec;
  aspectRatio?: number;
  responsiveAspectRatios?: DeviceSpec;
};

/**
 * One use-case of this component is to show a contentful image with optimized props (instead of the deprecated Image contentful element)
 */
const Image = (props: ImageProps) => {
  const {
    className,
    alt,
    spans,
    aspectRatios,
    fit,
    maxWidth,
    fileName,
    url,
    disableContentfulScaling,
    lazyLoad,
    fullBleed,
  } = props;

  const extraStyles = getStyles(props);

  const picture = (
    <Picture
      className={className}
      css={extraStyles.css}
      alt={alt}
      fileName={fileName}
      url={url}
      aspectRatios={aspectRatios}
      spans={spans}
      fit={fit}
      maxWidth={maxWidth}
      disableContentfulScaling={disableContentfulScaling}
      lazyLoad={lazyLoad}
      fullBleed={fullBleed}
    />
  );

  if (lazyLoad) {
    return <Wrapper {...props}>{picture}</Wrapper>;
  }

  return (
    <Wrapper {...props} wrapperCss={css([props.wrapperCss, extraStyles.wrapperCss])}>
      {picture}
    </Wrapper>
  );
};

const getStyles = (props: ImageProps) => {
  if (props.fitWrapper) {
    return getImageFitStyles();
  }
  return { css: undefined, wrapperCss: undefined };
};

const Picture = (props: Parameters<typeof getPicture>[0] & ImageProps) => {
  const Pic = getPicture(props);
  return <Pic {...props} />;
};

const Wrapper = (props: ImageProps & { children: JSX.Element }) => {
  const { children } = props;
  if (props.fitWrapper) {
    const { deviceWidths, aspectRatio, responsiveAspectRatios, wrapperCss } = props;
    return (
      <ImageFitWrapper
        deviceWidths={deviceWidths}
        aspectRatio={aspectRatio}
        responsiveAspectRatios={responsiveAspectRatios}
        css={wrapperCss}
      >
        {children}
      </ImageFitWrapper>
    );
  }
  return children;
};

export default memo(Image);
