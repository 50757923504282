import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeBackgroundSkeleton } from './TypeBackground';
import type { TypeBuildingBlockSkeleton } from './TypeBuildingBlock';
import type { TypeOrientedMediaSkeleton } from './TypeOrientedMedia';

export interface TypePageSectionFields {
  identifier: EntryFieldTypes.Symbol;
  type: EntryFieldTypes.Symbol<
    | 'big-paragraph'
    | 'campaigns'
    | 'cause-pages'
    | 'centered-info'
    | 'cta-push'
    | 'form'
    | 'headliner'
    | 'hero-donation'
    | 'hero-graphic'
    | 'hero-standard'
    | 'highlight'
    | 'highlight-bleed'
    | 'horizontal-list'
    | 'impacters'
    | 'partner-logos'
    | 'ppc-content-updates'
    | 'ppc-donation'
    | 'ppc-hero'
    | 'ppc-map'
    | 'ppc-terms-and-conditions'
    | 'questions-and-answers'
    | 'quotes'
    | 'small-list'
    | 'two-column'
    | 'zoom-video'
    | 'zoom-video-partner'
  >;
  backgroundEntry?: EntryFieldTypes.EntryLink<TypeBackgroundSkeleton>;
  buildingBlock?: EntryFieldTypes.EntryLink<TypeBuildingBlockSkeleton>;
  orientedMedia?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  reversed?: EntryFieldTypes.Boolean;
  reversedMobile?: EntryFieldTypes.Boolean;
  variant?: EntryFieldTypes.Symbol<'dark' | 'light'>;
  references?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<EntrySkeletonType>>;
  footer?: EntryFieldTypes.EntryLink<TypeBuildingBlockSkeleton>;
  title?: EntryFieldTypes.RichText;
  description?: EntryFieldTypes.RichText;
  help?: EntryFieldTypes.RichText;
  image?: EntryFieldTypes.AssetLink;
  background?: EntryFieldTypes.AssetLink;
  settings?: EntryFieldTypes.Object;
}

export type TypePageSectionSkeleton = EntrySkeletonType<TypePageSectionFields, 'pageSection'>;
export type TypePageSection<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypePageSectionSkeleton, Modifiers, Locales>;

export function isTypePageSection<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypePageSection<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'pageSection';
}
