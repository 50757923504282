import Head from 'next/head';
import { TypeMetaHeader } from '../../../@types/generated';

export type MetaHeaderProps = {
  seoDescription: string;
  seoTitle: string;
  socialDescription: string;
  socialImageUrl: string;
  socialImageHeight: number;
  socialImageWidth: number;
  socialTitle: string;
  canonicalLink: string;
};

type Overrides = {
  socialDescription?: string;
  socialTitle?: string;
  socialImageUrl?: string;
};

export const transformMetaHeaderType = ({
  metaHeader,
  overrides,
}: {
  metaHeader?: TypeMetaHeader;
  overrides?: Overrides;
}): MetaHeaderProps => {
  return {
    seoDescription: metaHeader?.fields.seoDescription || '',
    seoTitle: metaHeader?.fields.seoTitle || '',
    socialDescription: overrides?.socialDescription ?? (metaHeader?.fields.socialDescription || ''),
    socialImageUrl:
      overrides?.socialImageUrl ?? (metaHeader?.fields.socialImage?.fields.file?.url || ''),
    socialImageHeight: metaHeader?.fields.socialImageHeight || 0,
    socialImageWidth: metaHeader?.fields.socialImageWidth || 0,
    socialTitle: overrides?.socialTitle ?? (metaHeader?.fields.socialTitle || ''),
    canonicalLink: metaHeader?.fields.canonicalLink || '',
  };
};

type Props = {
  metaHeader: MetaHeaderProps | undefined;
};

const MetaHeader = ({ metaHeader }: Props) => {
  const {
    seoDescription,
    seoTitle,
    socialDescription,
    socialImageUrl,
    socialImageHeight,
    socialImageWidth,
    socialTitle,
    canonicalLink,
  } = metaHeader || {};

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta key="description" name="description" content={seoDescription} />
      <meta key="og:title" property="og:title" content={socialTitle} />
      <meta key="og:description" property="og:description" content={socialDescription} />
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      {socialImageUrl && (
        <meta key="og:image" property="og:image" content={`https:${socialImageUrl}`} />
      )}
      {socialImageUrl && socialImageWidth && (
        <meta key="og:image:width" property="og:image:width" content={String(socialImageWidth)} />
      )}
      {socialImageUrl && socialImageHeight && (
        <meta
          key="og:image:height"
          property="og:image:height"
          content={String(socialImageHeight)}
        />
      )}
      <script
        key="schema.org"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'organization',
            name: seoTitle,
            image: [`https:${socialImageUrl}`],
            description: seoDescription,
          }),
        }}
      />
    </Head>
  );
};

export default MetaHeader;
