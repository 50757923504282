import getConfig from 'next/config';
import publicRuntimeConfigForTyping from '../../config/publicRuntimeConfig';
import isBrowser from './helpers/isBrowser';
import { getApiUrl, getCdnApiUrl, getHostUrl } from './urls';

const config = getConfig();
const publicRuntimeConfig: typeof publicRuntimeConfigForTyping = config.publicRuntimeConfig;

/**
 * Uses relative url for browsers so using 'localhost' is not a must in dev-staging
 */
const apiBaseUrlGetter = (getApiBaseUrl: (env: string) => string) => {
  if (
    (publicRuntimeConfig.ENV === 'dev-staging' ||
      publicRuntimeConfig.ENV === 'test-staging' ||
      publicRuntimeConfig.ENV === 'dev-production') &&
    isBrowser()
  ) {
    return `/dev-proxy/`;
  }
  return getApiBaseUrl(publicRuntimeConfig.ENV);
};

export default {
  ...publicRuntimeConfig,
  HOST_URL: getHostUrl(publicRuntimeConfig.ENV),
  CDN_API_URL: apiBaseUrlGetter(getCdnApiUrl),
  API_URL: apiBaseUrlGetter(getApiUrl),
};
