import { Close, Menu } from '@carbon/icons-react';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import HeaderWrapper from '../../component-library/navigation/NavBar/HeaderWrapper';
import MobileSubmenu from '../../component-library/navigation/NavBar/LinksGroup/MobileSubmenu';
import MobileNavBar from '../../component-library/navigation/NavBar/MobileNavBar';
import NavBarContainer from '../../component-library/navigation/NavBar/NavBarContainer';
import PoweredByMWNavBar from '../../component-library/navigation/NavBar/PoweredByMWNavBar';
import { Home, Props, useNavBar } from './common';

const BurgerNavigation = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  height: 32px;
`;

const ContentfulMobileBar = ({ navigationBar, initiallyTransparent, hideNavbarLogo }: Props) => {
  const {
    submenu,
    clearSubmenu,
    linkGroups,
    navButton,
    handleNavItemSelected,
    mobileBottomLinks,
    isMenuOpened,
    setIsMenuOpened,
    logoGroup,
    poweredByMilkywire,
    textColor,
  } = useNavBar({ navigationBar });

  const mobileClickOutSide = useCallback(
    (event: Event) => {
      event.stopPropagation();
      if (
        !event.target ||
        !(event.target instanceof Element) ||
        !event.target?.closest('#navbar')
      ) {
        setIsMenuOpened(false);
      }
    },
    [setIsMenuOpened],
  );

  const toggleMobileMenu = useCallback(() => {
    if (isMenuOpened) {
      clearSubmenu();
    }
    setIsMenuOpened((o) => !o);
  }, [isMenuOpened, setIsMenuOpened, clearSubmenu]);

  if (poweredByMilkywire) return <PoweredByMWNavBar color={textColor || undefined} />;

  return (
    <HeaderWrapper
      initiallyTransparent={initiallyTransparent}
      overrideTransparent={isMenuOpened}
      hideNavBar={clearSubmenu}
      preventHideNavBar={isMenuOpened}
      textColor={textColor || undefined}
    >
      {({ textColor }) => (
        <>
          <MobileNavBar
            isOpened={isMenuOpened}
            linkGroups={linkGroups}
            handleNavItemClick={handleNavItemSelected}
            button={navButton}
            bottomLinks={mobileBottomLinks}
            clickOutside={mobileClickOutSide}
          />
          <MobileSubmenu
            isOpened={isMenuOpened}
            submenu={submenu}
            goBack={clearSubmenu}
            clickOutside={mobileClickOutSide}
            onLinkedClicked={toggleMobileMenu}
          />

          <NavBarContainer>
            {!hideNavbarLogo && (
              <Home
                textColor={textColor}
                handleNavItemClick={handleNavItemSelected}
                logoGroup={logoGroup}
              />
            )}
            {linkGroups.length ? (
              <BurgerNavigation onClick={toggleMobileMenu} aria-label={'menu'}>
                {isMenuOpened ? (
                  <Close fill={textColor} size={32} />
                ) : (
                  <Menu fill={textColor} size={32} />
                )}
              </BurgerNavigation>
            ) : null}
          </NavBarContainer>
        </>
      )}
    </HeaderWrapper>
  );
};

export default ContentfulMobileBar;
