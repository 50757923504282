import { PaymentMethod } from '../utils/api';
import { PreviewSubscriptionResponse } from '../utils/api/subscription';

export type Provider = string;

export type Currency = {
  code: string;
  name: string;
  symbol?: string;
  cents?: number;
};

export enum PaymentType {
  ONE_TIME = 'one-time',
  SUBSCRIPTION = 'subscription',
}

export enum FlowType {
  ONE_TIME = 'one time',
  SUBSCRIPTION = 'subscription',
}

export enum SubscriptionFlowType {
  createSubscription = 'create subscription',
  updateSubscription = 'update subscription',
}

export type DonationPlan = 'one time' | 'monthly';

export type DonationTarget = 'quickfix' | 'category' | 'campaign';

export type Donation = {
  currencyCode?: string;
  amount?: number;
  isValid?: boolean;
  isCustomAmount?: boolean;
  plan: DonationPlan;
  provider?: Provider;
  clientId?: string; // unique id for reference (analytics...)
  paymentMethodId?: string;
  impacterIds?: number[];
  target?: DonationTarget;
  isCompleteEdit?: boolean;
  tagNames?: string[];
};

export type Affiliate = {
  name?: string;
  publishable?: boolean;
  message?: string;
};

export type Subscription = {
  pending?: boolean;
  error?: boolean;
  errorMessage?: string;
  targetError?: 'no-impacter';
  fulfilled?: boolean;
  nextPayment?: Date;
  externalId?: string;
  details?: PreviewSubscriptionResponse['subscriptionDetails'];
};

type ProviderData = {
  id: Provider;
  currencyCodes?: string[];
  paymentMethods?: PaymentMethod[];
  paymentMethodsStatus?: { ready: boolean };
  publicKey?: string;
};

export type PreviousSubscription = {
  currency: Currency;
  currencyCode: string;
  amount: number;
  impacterIds: number[];
};

export type PaymentState = {
  paymentFlowId?: string;
  defaultCurrencyCode?: string;
  activeCurrencyCode?: string;
  amountOptions: Record<string, { amount: number; isDefault: boolean }[] | undefined>;
  amountOptionsStatus: Record<string, { loading: boolean } | undefined>;
  currencies: Record<string, Currency | undefined>;
  providers: Record<string, ProviderData | undefined>;
  donation?: Donation;
  subscription?: Subscription;
  previousSubscription?: PreviousSubscription;
  affiliate?: Affiliate;
};

export type PaymentModuleState = {
  payment: PaymentState;
};
