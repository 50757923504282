import { ChevronLeft } from '@carbon/icons-react';
import styled from '@emotion/styled';
import { useRef } from 'react';
import { useOutsideClick } from '../../../../../utils/hooks/useOutsideClick';
import { colors, spacing } from '../../../../../utils/styleguide';
import Typography from '../../../text/Typography';
import { NavItemType, NavLinkItemType } from '../../navTypes';
import MobileNavBarWrapper from '../MobileNavBarWrapper';
import SubmenuLink from './SubmenuLink';

const SubmenuHeader = styled.button`
  display: flex;
  align-items: center;
  gap: ${spacing[2]}px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: ${spacing[1]}px 0;
  margin: ${spacing[0]}px 0;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;

  width: 100%;
  margin-top: ${spacing[4]}px;

  border-left: 1px solid ${colors.grey02};
`;

type MobileSubmenuProps = {
  submenu: NavLinkItemType | null | undefined;
  isOpened: boolean;
  goBack: () => void;
  clickOutside: (event: Event) => void;
  onLinkedClicked: () => void;
};

const MobileSubmenu = ({
  submenu,
  isOpened,
  goBack,
  clickOutside,
  onLinkedClicked,
}: MobileSubmenuProps) => {
  const mobileSubmenuRef = useRef(null);
  useOutsideClick({ innerRef: mobileSubmenuRef, onOutsideClick: clickOutside });

  return (
    <MobileNavBarWrapper isOpened={!!submenu && isOpened} ref={mobileSubmenuRef}>
      <SubmenuHeader onClick={goBack} aria-label={'back'}>
        <ChevronLeft color={colors.blackPrimary} />
        <Typography variant="buttonSmall" tag="p">
          {submenu?.title}
        </Typography>
      </SubmenuHeader>

      {submenu?.links && (
        <LinksContainer>
          {submenu.links?.map(({ title, href, richText, description }: NavItemType, index) => (
            <SubmenuLink
              key={index}
              href={href}
              title={title ?? richText}
              description={description}
              onClick={onLinkedClicked}
            />
          ))}
        </LinksContainer>
      )}
    </MobileNavBarWrapper>
  );
};

export default MobileSubmenu;
